import React from 'react';
import { Flex, Box, Text } from 'rebass';
import DrawerPlus from '../Icons/DrawerPlus';
import DrawerArrow from '../Icons/DrawerArrow';
import { Transition, animated } from 'react-spring/renderprops.cjs';
import Contenu from './Contenu';
import DrawerClose from '../Icons/DrawerClose';
import { FormattedHTMLMessage } from 'react-intl';

// const Collapse = posed.div({
//   closed: { height: 0 },
//   open: { height: 'auto' },
// });

class Chapitre extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  toggle = e => this.setState(state => ({ show: !state.show }));

  render() {
    return (
      <Flex
        width={1}
        flexWrap="wrap"
        py={5}
        alignItems="flex-end"
        id={this.props.id}
        mx="auto"
        css={{ maxWidth: '1200px' }}
      >
        <Text textAlign="center" width={[1, 1 / 2]}>
          <Text
            fontSize={['14px', '20px']}
            lineHeight={1}
            css={{ textTransform: 'uppercase', letterSpacing: '0.1em' }}
          >
            <FormattedHTMLMessage id="home.chapitre" />
          </Text>
          <Text
            fontSize={['200px', '250px']}
            className="textstroke"
            css={{ position: 'relative', transform: 'translateY(40px)' }}
          >
            {this.props.chapitre}
            {this.props.chapitre == '02' && (
              <Text
                fontSize={['200px', '250px']}
                className="textstroke"
                css={{ position: 'absolute', top: '-20px', width: '100%' }}
              >
                {this.props.chapitre}
              </Text>
            )}
            {this.props.chapitre == '03' && (
              <Text
                fontSize={['200px', '250px']}
                className="textstroke"
                css={{ position: 'absolute', top: '-20px', width: '100%' }}
              >
                {this.props.chapitre}
              </Text>
            )}
            {this.props.chapitre == '03' && (
              <Text
                fontSize={['200px', '250px']}
                className="textstroke"
                css={{ position: 'absolute', top: '-40px', width: '100%' }}
              >
                {this.props.chapitre}
              </Text>
            )}
          </Text>
        </Text>
        <Text
          lineHeight={['32px', '64px']}
          fontSize={['30px', '58px']}
          px={[3, 0]}
          pt={[5, 0]}
          mb={[0, '8px']}
          width={[1, 1 / 2]}
          textAlign={['center', 'left']}
        >
          {this.props.titre}
        </Text>
        <Text pt={5} width={1} textAlign="center">
          <button onClick={this.toggle} type="button" className="plain">
            {this.state.show ? <DrawerArrow /> : <DrawerPlus />}
          </button>
          <Transition
            native
            items={this.state.show}
            from={{ height: 0, overflow: 'hidden' }}
            enter={[{ height: 'auto', position: 'relative', overflow: 'visible' }]}
            leave={{ height: 0, overflow: 'hidden' }}
          >
            {show =>
              show &&
              (props => (
                <animated.div style={props}>
                  <Box
                    width={1 / 10}
                    className={this.state.show ? null : 'hidden'}
                    onClick={() => this.setState({ show: false })}
                    css={{
                      overflow: 'visible',
                      position: 'absolute',
                      left: '0',
                      top: '0',
                      height: '100%',
                    }}
                  >
                    <div css={{ position: 'sticky', top: '100px', marginLeft: '1rem' }}>
                      <button className="plain">
                        <DrawerClose />
                      </button>
                    </div>
                  </Box>
                  <Contenu handler={this.toggle} chapitre={this.props.chapitre} />
                  <Text mt={3} fontSize={3}>
                    <FormattedHTMLMessage id="home.more" />
                  </Text>
                </animated.div>
              ))
            }
          </Transition>
        </Text>
      </Flex>
    );
  }
}

export default Chapitre;
