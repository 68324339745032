import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Flex, Box, Text, Card } from 'rebass';
import withLayout from '../layout';
import Helmet from 'react-helmet';
import Hide from '../components/Hide';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import PreloadImages from '../components/PreloadImages';

import Chapitre from '../components/Chapitre';
import Article from '../components/Article';
import { Stickyroll } from '@stickyroll/stickyroll';

const images = [
  'https://cside.sfo2.cdn.digitaloceanspaces.com/journals/01/HS_01_fr.jpg',
  'https://cside.sfo2.cdn.digitaloceanspaces.com/journals/01/HS_02_fr.jpg',
  'https://cside.sfo2.cdn.digitaloceanspaces.com/journals/01/HS_03_fr.jpg',
  'https://cside.sfo2.cdn.digitaloceanspaces.com/journals/01/HS_04_fr.jpg',
];

const images_en = [
  'https://cside.sfo2.cdn.digitaloceanspaces.com/journals/01/HS_01_EN.jpg',
  'https://cside.sfo2.cdn.digitaloceanspaces.com/journals/01/HS_02_EN.jpg',
  'https://cside.sfo2.cdn.digitaloceanspaces.com/journals/01/HS_03_EN.jpg',
  'https://cside.sfo2.cdn.digitaloceanspaces.com/journals/01/HS_04_EN.jpg',
];

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "intro.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  render() {
    const locale = this.props.pageContext.locale;
    return (
      <>
        <Helmet>
          <html lang="fr" />
        </Helmet>
        <Box bg="black" mb={[0, '50px']}>
          <Stickyroll pages={2}>
            {({ page, pageIndex, pages, progress }) => {
              const ecrantransform =
                page === 1 ? `translateX(${(progress * 10000) / 500 - 20}%)` : null;

              const vertstransform =
                page === 1 ? `translateX(${(progress * 10000) / -200 + 50}%)` : null;

              const imagetransform =
                page === 1 ? `translateY(${(progress * 10000) / -200 + 50}%)` : null;
              const styles = {
                ecrans: {
                  transform: ecrantransform,
                },
                verts: {
                  transform: vertstransform,
                },
                image: {
                  transform: imagetransform,
                },
              };

              return (
                <div>
                  <div>
                    <Box px={[0, 5]} pt={['60px', '100px']} css={{ overflow: 'hidden' }} width={1}>
                      <Box width={1} mx="auto" px={[1, 0]} css={{ maxWidth: '1200px' }}>
                        <Text
                          className={`textstroke textstroke--${page}`}
                          fontSize={['111px', '200px']}
                          ml={['-5px', '-20px']}
                          lineHeight={['100px', '200px']}
                          style={styles.ecrans}
                        >
                          <FormattedHTMLMessage id="home.title1" />
                        </Text>
                        <Text
                          ml={['0', '27%']}
                          mt="-0.35em"
                          className={`textstroke textstroke--${page}`}
                          fontSize={['111px', '200px']}
                          style={styles.verts}
                        >
                          <FormattedHTMLMessage id="home.title2" />
                        </Text>
                      </Box>
                      <Flex
                        width={1}
                        flexWrap="wrap"
                        css={{ maxWidth: '1200px' }}
                        mx="auto"
                        pb={[1, '115px']}
                      >
                        <Box width={[1 / 2, 1 / 4]} pl={[2, 0]} mt={[1, '-148px']}>
                          <Img fluid={this.props.data.fileName.childImageSharp.fluid} />
                        </Box>
                        <Flex
                          width={[1, 1 / 2]}
                          pt={2}
                          mb={[0, '-6px']}
                          pl={[2, 3]}
                          fontSize={['20px', '25px']}
                          className={`fade fade--${page}`}
                          alignItems="flex-end"
                        >
                          <FormattedHTMLMessage id="home.subtitle" />
                        </Flex>
                      </Flex>
                    </Box>
                  </div>
                </div>
              );
            }}
          </Stickyroll>
        </Box>
        <Flex
          bg="orange"
          color="black"
          pt={[4, 6]}
          px={[2, 0]}
          alignItems="center"
          flexDirection="column"
        >
          <Flex
            fontSize={[3]}
            width={[1]}
            flexWrap="wrap"
            css={{ maxWidth: '1260px' }}
            mx={[0, -3]}
            mb={-3}
            justifyContent="space-between"
          >
            <Box width={[1, 1 / 2]} px={[0, 3]}>
              <FormattedHTMLMessage id="home.orangeintro" />
            </Box>
            <Box width={[1, 1 / 2]} px={[0, 3]}>
              <FormattedHTMLMessage id="home.orangeintro2" />
            </Box>
          </Flex>
          <Box width={1} mx="auto" css={{ maxWidth: '1200px' }}>
            <Stickyroll pages={4}>
              {({ page, pageIndex, pages, progress }) => {
                return (
                  <Flex
                    width={1}
                    pt={[5]}
                    flexWrap="wrap"
                    mx={[0, -3]}
                    justifyContent="space-between"
                  >
                    <Box width={[1, 1 / 2]} px={[0, 3]} pt={[0, 5]}>
                      {locale === 'fr' && (
                        <img src={images[pageIndex]} css={{ maxWidth: '100%' }} />
                      )}
                      {locale === 'en' && (
                        <img src={images_en[pageIndex]} css={{ maxWidth: '100%' }} />
                      )}
                    </Box>
                    <Box width={[1, 2 / 5]} pt={[0, 4]}>
                      <Flex width={[1]} py={[1, 3]}>
                        <Box width={[1 / 3]} className={`fade fade--${page > 1 ? 'in' : null}`}>
                          <Text fontSize={'30px'} lineHeight={1}>
                            <FormattedHTMLMessage id="home.orangeMetaPages" />
                          </Text>
                        </Box>
                        <Box width={[2 / 3]} className={`fade fade--${page > 1 ? 'in' : null}`}>
                          <Text fontSize={['30px', '30px']} lineHeight={[1, 1.2]}>
                            <FormattedHTMLMessage id="home.orangeMetaTitle" />
                          </Text>
                        </Box>
                      </Flex>
                      <Flex width={[1]} py={[1, 3]}>
                        <Box width={[1 / 3]} className={`fade fade--${page > 2 ? 'in' : null}`}>
                          <Text fontSize={['20px', '25px']}>
                            <FormattedHTMLMessage id="home.orangeMetaSubtitle" />
                          </Text>
                        </Box>
                        <Flex
                          width={[2 / 3]}
                          flexWrap="wrap"
                          className={`fade fade--${page > 3 ? 'in' : null}`}
                        >
                          <Text fontSize={['20px', '25px']} width={[1]}>
                            <FormattedHTMLMessage id="home.orangeMetaNames" />
                          </Text>
                          <Text fontSize={['20px', '25px']} width={[1]}>
                            <Hide xsmall>
                              <FormattedHTMLMessage id="home.orangeMetaNames2" />
                            </Hide>
                          </Text>
                        </Flex>
                      </Flex>
                    </Box>
                  </Flex>
                );
              }}
            </Stickyroll>
          </Box>
        </Flex>
        <div id="carnet" />
        <div id="journal" />
        <FormattedHTMLMessage id="home.chapitre01">
          {txt => <Chapitre id="chapitre01" chapitre="01" titre={txt} />}
        </FormattedHTMLMessage>
        <hr />

        <FormattedHTMLMessage id="home.chapitre02">
          {txt => <Chapitre id="chapitre02" chapitre="02" titre={txt} />}
        </FormattedHTMLMessage>
        <hr />

        <FormattedHTMLMessage id="home.chapitre03">
          {txt => <Chapitre id="chapitre03" chapitre="03" titre={txt} />}
        </FormattedHTMLMessage>
        <hr />
        <FormattedHTMLMessage id="emojisarticle.title">
          {txt => <Article titre={txt} />}
        </FormattedHTMLMessage>
      </>
    );
  }
}

const customProps = {
  localeKey: 'home', // same as file name in src/i18n/translations/your-lang/index.js
  emojiArticleKey: 'emojisarticle', // same as file name in src/i18n/translations/your-lang/index.js
  contenu01Key: 'contenu01', // same as file name in src/i18n/translations/your-lang/index.js
  contenu02Key: 'contenu02', // same as file name in src/i18n/translations/your-lang/index.js
  contenu03Key: 'contenu03', // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(IndexPage);
