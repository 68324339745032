import React from 'react';
import { Flex, Box, Text } from 'rebass';
import DrawerPlus from '../Icons/DrawerPlus';
import DrawerArrow from '../Icons/DrawerArrow';
import Emoji from '../Emoji';
import { Transition, animated } from 'react-spring/renderprops.cjs';

import DrawerClose from '../Icons/DrawerClose';
import { FormattedHTMLMessage } from 'react-intl';

// const Collapse = posed.div({
//   closed: { height: 0 },
//   open: { height: 'auto' },
// });

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  toggle = e => this.setState(state => ({ show: !state.show }));

  render() {
    return (
      <Box p={[2, 5]} id="emojis">
        <Text
          textAlign="center"
          width={[1]}
          fontSize={['14px', '20px']}
          lineHeight={1}
          css={{ textTransform: 'uppercase', letterSpacing: '0.1em' }}
        >
          <FormattedHTMLMessage id="emojisarticle.extrait" />
        </Text>
        <Text
          lineHeight={['30px', '50px']}
          fontSize={['30px', '50px']}
          px={3}
          m="auto"
          pt={[5]}
          width={[1, 3 / 5]}
          textAlign="left"
        >
          {this.props.titre}
        </Text>
        <FormattedHTMLMessage id="emojisarticle.author">
          {txt => (
            <Text
              lineHeight={['32px', '68px']}
              fontSize={['30px', '50px']}
              px={3}
              m="auto"
              pt={[3]}
              width={[1, 3 / 5]}
              textAlign="right"
            >
              {txt}
            </Text>
          )}
        </FormattedHTMLMessage>

        <Box
          id="emojis"
          width={[1, 3 / 5]}
          m="auto"
          px={3}
          fontSize={['3.5vw', '2vw']}
          py={(2, 5)}
          css={{
            '& p': {
              width: '83%',
              marginBottom: '2em',
            },
            '& p:nth-child(even)': {
              marginLeft: 'auto',
              hyphens: 'auto',
            },
            '& p:nth-child(odd)': {
              marginRight: 'auto',
              hyphens: 'auto',
            },
          }}
        >
          <Text textAlign="center" fontSize={['40px', '50px']} pb={[5]}>
            <Emoji symbol="🌧️" label="cloudwithrain" /> <Emoji symbol="🏢" label="officebuilding" />{' '}
            <Emoji symbol="👳🏽‍♂️" label="manwearingturbanmediumskintone" />
          </Text>
          <FormattedHTMLMessage id="emojisarticle.text" />
        </Box>
        <Text pb={5} width={1} textAlign="center" fontSize={['3.5vw', '2vw']}>
          <button onClick={this.toggle} type="button" className="btn btn-emoji">
            {this.state.show ? (
              <>
                <Emoji symbol="🙄" label="Face With Rolling Eyes" />
                <Emoji symbol="☝️" label="Index Pointing Up" />
              </>
            ) : (
              <>
                <Emoji symbol="👀" label="Eyes" />
                <Emoji symbol="👇" label="Backhand Index Pointing Down" />
              </>
            )}
          </button>
        </Text>
        <Transition
          native
          items={this.state.show}
          from={{ height: 0, overflow: 'hidden' }}
          enter={[{ height: 'auto', position: 'relative', overflow: 'visible' }]}
          leave={{ height: 0, overflow: 'hidden' }}
        >
          {show =>
            show &&
            (props => (
              <animated.div style={props}>
                <Box
                  width={1 / 10}
                  className={this.state.show ? null : 'hidden'}
                  onClick={() => this.setState({ show: false })}
                  css={{
                    overflow: 'visible',
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    height: '100%',
                  }}
                >
                  <div css={{ position: 'sticky', top: '100px' }}>
                    <DrawerClose />
                  </div>
                </Box>
                <Box
                  width={[1, 3 / 5]}
                  m="auto"
                  fontSize={['3.5vw', '2vw']}
                  p={2}
                  css={{
                    '& p': {
                      width: '83%',
                      marginBottom: '2em',
                    },
                    '& p:nth-child(even)': {
                      marginLeft: 'auto',
                      hyphens: 'auto',
                    },
                    '& p:nth-child(odd)': {
                      marginRight: 'auto',
                      hyphens: 'auto',
                    },
                  }}
                >
                  <FormattedHTMLMessage id="emojisarticle.more" />
                </Box>
                <Box id="emojis" width={[1, 3 / 5]} m="auto" fontSize={['3vw', '1.5vw']} p={2}>
                  <Text width={[1, '83%']} ml="auto">
                    &mdash;
                    <FormattedHTMLMessage id="emojisarticle.credit" />
                  </Text>
                </Box>
              </animated.div>
            ))
          }
        </Transition>
      </Box>
    );
  }
}

export default Article;
