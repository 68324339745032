import React from 'react';
import { Card, Box, Text, Flex } from 'rebass';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

class Contenu extends React.Component {
  render() {
    const chapitre = this.props.chapitre;
    return (
      <>
        <Text textAlign="center" width={1}>
          <Box pt={3} css={{ textTransform: 'uppercase' }}>
            <FormattedHTMLMessage id={`contenu${chapitre}.type01`} />
          </Box>
          <Text as="h2" fontSize={5} py={[4, 5]}>
            <FormattedHTMLMessage id={`contenu${chapitre}.title01`} />
          </Text>
          <Box pb={[5]} width={[1, 3 / 4]} m="auto">
            <FormattedMessage id={`contenu${chapitre}.featuredImage01`}>
              {txt => (
                <Card
                  width={1}
                  pt={['100%', '56%']}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  backgroundImage={txt}
                />
              )}
            </FormattedMessage>
          </Box>
          <Text as="h2" fontSize={[5, '50px']}>
            <FormattedHTMLMessage id={`contenu${chapitre}.author01`} />
          </Text>
          <Text as="p" m="auto" pt={3} pb={5} width={[1, 1 / 3]} fontSize={['16px', '25px']}>
            <FormattedHTMLMessage id={`contenu${chapitre}.description01`} />
          </Text>
        </Text>
        <Box pb={[0, 5]} px={[5, 0]} width={[1, 1 / 4]} m="auto">
          <FormattedMessage id={`contenu${chapitre}.profileImage01`}>
            {txt => (
              <Card
                width={1}
                pt={['100%', '100%']}
                backgroundImage={txt}
                backgroundSize="cover"
                backgroundPosition="center"
              />
            )}
          </FormattedMessage>
        </Box>
        <Text textAlign="center" width={1}>
          <Text
            as="p"
            textAlign="left"
            fontSize={['25px', '30px']}
            p={2}
            m="auto"
            width={[1, 1 / 2]}
          >
            <FormattedHTMLMessage id={`contenu${chapitre}.excerpt01`} />
          </Text>
          <Flex justifyContent="center" py={4}>
            <Box bg="white" width="1px" css={{ height: '288px' }} />
          </Flex>
          <Box pt={3} css={{ textTransform: 'uppercase' }}>
            <FormattedHTMLMessage id={`contenu${chapitre}.type02`} />
          </Box>
          <Text as="h2" fontSize={5} py={[4, 5]}>
            <FormattedHTMLMessage id={`contenu${chapitre}.title02`} />
          </Text>
          <Box pb={[5]} width={[1, 3 / 4]} m="auto">
            <FormattedMessage id={`contenu${chapitre}.featuredImage02`}>
              {txt => (
                <Card
                  width={1}
                  pt={['100%', '56%']}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  backgroundImage={txt}
                />
              )}
            </FormattedMessage>
          </Box>
          <Text as="h2" fontSize={[5, '50px']}>
            <FormattedHTMLMessage id={`contenu${chapitre}.author02`} />
          </Text>
          <Text as="p" m="auto" pt={3} pb={5} width={[1, 1 / 3]} fontSize={['16px', '25px']}>
            <FormattedHTMLMessage id={`contenu${chapitre}.description02`} />
          </Text>
        </Text>
        <Box pb={[0, 5]} px={[5, 0]} width={[1, 1 / 4]} m="auto">
          <FormattedMessage id={`contenu${chapitre}.profileImage02`}>
            {txt => (
              <Card
                width={1}
                pt={['100%', '100%']}
                backgroundImage={txt}
                backgroundSize="cover"
                backgroundPosition="center"
              />
            )}
          </FormattedMessage>
        </Box>
        <Text as="p" textAlign="left" fontSize={['25px', '30px']} p={2} m="auto" width={[1, 1 / 2]}>
          <FormattedHTMLMessage id={`contenu${chapitre}.excerpt02`} />
        </Text>
      </>
    );
  }
}

export default Contenu;
